<template>
  <div v-if="show" class="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-40 z-30 flex justify-center items-center">
    <div class="relative w-1/3 no-scrollbar overflow-y-auto bg-white rounded-xl ">
      <button class="absolute top-3 right-3" @click="onCancel()">
        <svg class="w-8 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
      <div class="flex flex-col " action="#">
        <div class="p-8">
          <p>
            Bestellung abgeschlossen. Ihr Auftrag befindet sich in der Bearbeitung. Wir informieren Sie per Email, sobald wir weitere Neuigkeiten haben. Sie können den Status Ihrer Beauftragung auch jederzeit online in unserem Portal unter Bestellübersicht einsehen.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {ref, onMounted, computed} from 'vue';

export default ({
  name: 'InformationModal',
  props: {
    show: Boolean,
    toggleShow: Function,
    toggleReloadOrders: Function,
  },

  setup(props) {
    const {t} = useI18n();
    const selectedServices = ref([]);
    const toggleShow = props.toggleShow;
    const selectedCustomer = ref();

    onMounted(async () => {
    })

    const onCancel = () => {
      toggleShow();
      selectedServices.value = [];
      selectedCustomer.value = null;
    }
    return {
      t,
      toggleShow,
      selectedCustomer,
      selectedServices,
      onCancel,
    };
  },
});

</script>

<style lang="scss">
</style>


