<template>
  <div v-if="show" class="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-40 z-30 flex justify-center items-center">
    <div class="help-modal-container relative w-3/4 h-screen max-h-full no-scrollbar overflow-y-auto bg-white rounded-xl ">
      <button class="absolute top-3 right-3" @click="onCancel()">
        <svg class="w-8 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
     
      <div class="flex flex-col " action="#">
        <div class="help-modal-header p-8">
          <h3>
            Wie können wir Ihnen weiterhelfen?
          </h3>
          <p>
            Wir würden gerne wissen, wie wir Ihnen beim Vervollständigen Ihrer Bestellung unterstützen können? Dafür können wir Ihnen folgende Möglichkeiten anbieten:
          </p>
          <div class="help-modal-contact">
            <ul>
              <li>
                  Rufen Sie uns gerne jederzeit an: +49 201 / 4585 34 74
              </li>
              <li>
                  Lesen Sie unser vollständiges FAQ hier: <a target="_blank" :href="websiteFaqUrl"> {{ websiteFaqUrl }} </a>
              </li>
              <li>
                  Folgend haben wir die wichtigsten Punkte bereits zusammengefasst
              </li>
            </ul>
          </div>
        </div>
        <div class="help-modal-content p-8">
          <h3>
            Wie Sie Ihre Bestellung ausfüllen?
          </h3>
          <h5></h5>
          <div class="help-modal-diagrame">
            <img src="@/assets/help-modal-diagrame.png" alt="" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {ref, onMounted, computed} from 'vue';
import httpClient from '@/services/httpClient';

export default ({
  name: 'CreateOrder',
  props: {
    show: Boolean,
    toggleShow: Function,
    toggleReloadOrders: Function,
  },

  setup(props) {
    const {t} = useI18n();
    const selectedServices = ref([]);
    const servicesLoaded = ref();
    const customersLoaded = ref();
    const servicesList = ref([]);
    const toggleShow = props.toggleShow;
    const toggleReloadOrders = props.toggleReloadOrders;
    const customersList = ref([]);
    const selectedCustomer = ref();
    const selectedSource = ref();

    const fetchServices = async () => {
      servicesLoaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/services', {});

      servicesList.value = result.data;
      servicesLoaded.value = true;
    }

    const websiteFaqUrl = computed(() => {
      return httpClient.website('/faq');
    })

    const fetchCustomers = async () => {
      customersLoaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/all-customers', {});

      customersList.value = result.data;
      customersLoaded.value = true;
    }

    const postNewOrder = async () => {
      const services = selectedServices.value.filter(e => { return e.serviceId !== 0});

      const result = await httpClient.post(
        '/api/v1/dgr/order_create', {
          customer: selectedCustomer.value,
          orderSource:selectedSource.value,
          services: services,
        });
      console.log("Test", result)
    }

    const onAddService = (e) => {
      e.preventDefault();
      selectedServices.value.push({
        serviceId: 0,
        requiresPrivacy: false,
        quantity: 1,
      });
    }

    onMounted(async () => {
      await fetchServices();
      await fetchCustomers();
    })

    function getServicesList() {
      return servicesList.value;
    }

    function getCustomersList() {
      return customersList.value;
    }

    function getSelectedServices() {
      return selectedServices.value;
    }

    function getSelectedCustomer() {
      return selectedCustomer.value;
    }
    const onCreateOrder = async () => {
      await postNewOrder();
      onCancel();
      toggleReloadOrders();
      // console.warn('create', selectedServices);
    }

    const onCancel = () => {
      toggleShow();
      selectedServices.value = [];
      selectedCustomer.value = null;
    }
    const getOrderSourceOptions = () => {
      return [
        { name: 'HomePage', value: '10055' },
        { name: 'Email', value: '10056' },
        { name: 'Telefon', value: '10057' },
        { name: 'DEVK', value: '10059' },
        { name: 'AXA-Assistance', value: '10060' },
        { name: 'Deutsche-Assistance', value: '10061' },
        { name: 'ARAG', value: '10062' },
        { name: 'Andere', value: '10058' },
      ]
    }

    return {
      t,
      toggleShow,
      getServicesList,
      customersLoaded,
      getCustomersList,
      selectedCustomer,
      servicesLoaded,
      selectedServices,
      getSelectedCustomer,
      getSelectedServices,
      onCancel,
      onAddService,
      onCreateOrder,
      getOrderSourceOptions,
      selectedSource,
      websiteFaqUrl
    };
  },
});

</script>

<style lang="scss">
  .help-modal-container {
    height: 95%;
  }
  .help-modal-header {
    background-image: url('~@/assets/help-modal-header.png');
    background-size: cover;
    background-position: top center;
    color: white;
    padding: 150px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 115.68%;
      letter-spacing: 0.015em;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.015em;
      color: #FFFFFF;
      margin-bottom: 15px;
    }
    .help-modal-contact {
      margin-bottom: 15px;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 165.18%;
        letter-spacing: 0.015em;
        color: #FFFFFF;
        margin-bottom: 0;
      }
      ul {
        list-style-type: disc;
        list-style-position: inside;
      }
      li {
        margin-bottom: 10px;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  .help-modal-content {
    padding-top: 100px;
    padding-bottom: 100px;
    h3 {
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 34px;
      text-align: center;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      color: #2A2A2A;
      margin-bottom: 15px;
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 115.68%;
      text-align: center;
      letter-spacing: 0.015em;
      color: #000000;
      margin-bottom: 30px;
    }
  }
  .help-modal-diagrame {
    text-align: center;
    padding: 50px 0;
    img {
      display: inline-block;
    }
  }
</style>


