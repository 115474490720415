<template>
  <div class="h-full">
    <HelpModal
      :show="getShowCreateOrder()"
      :toggleShow="toggleCreateOrder" />
    <InformationModal
      :show="getShowSaveModal()"
      :toggleShow="toggleSaveModal" />
    <div class="my-8 mx-4 flex flex-col h-full"
         :class="jiraInProgress? 'opacity-20 pointer-events-none' : ''">
      <div class="flex text-sm w-full justify-start border-b border-gray-100 tabs-container">
        <button
          class="mx-px py-4 px-8 h-12 hover:bg-primary hover:text-white rounded-t focus:outline-none"
          :class="{'border-b-4 border-primary text-primary focus:outline-none':showSummary}"
          @click="setCurrentTab('summary')">
          {{ t('order_details.order_summary') }}
        </button>
        <button
          class="mx-px py-4 px-8 h-12 hover:bg-primary hover:text-white rounded-t focus:outline-none"
          :class="{'border-b-4 border-primary text-primary focus:outline-none':showBreakdown}"
          @click="setCurrentTab('breakdown')">
          {{ t('order_details.order_breakdown') }}
        </button>
        <span v-if="showBreakdown && unsavedChanges" class="unsaved-changes">{{ t('order_details.unsaved_changes') }}</span>
        <div style="margin-left:auto;top:-15px;position:relative;right: 15px;text-align:right;">
          <button
            class="help-button border border-primary rounded-3xl p-3 text-xs text-primary hover:bg-primary hover:text-white w-44 focus:outline-none mr-3"
            @click="toggleCreateOrder()"
            v-if="showBreakdown">
            {{ t('order_details.help') }}
          </button>
          <button
            class="bg-primary text-black w-44 rounded-3xl py-3 text-xs cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
            @click="onUpdateCustomOrderAllItems()"
            v-if="showBreakdown">
            {{ t('order_details.save') }}
          </button>
        </div>
      </div>

      <div v-if="showSummary" class="overflow-auto pb-6">
        <div class="my-6 mx-2 w-4/5 relative shadow-primary p-5 rounded-lg">
          <div class="flex flex-row items-start place-content-between">

            <div>
              <div class="font-bold text-xl">
                <div class="cursor-pointer" @click="toggleBillingDetails">
                  {{ orderDetails.billing?.company }}
                  {{ orderDetails.billing?.firstName }} {{ orderDetails.billing?.lastName }}
                  &raquo;
                </div>
                <OverlayPanel ref="billingDetails">
                  <div class="text-sm">
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.company') }}</div>
                      <InputText v-model="orderDetails.billing.company" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.first_name') }}</div>
                      <InputText v-model="orderDetails.billing.firstName" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.last_name') }}</div>
                      <InputText v-model="orderDetails.billing.lastName" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.address_1') }}</div>
                      <InputText v-model="orderDetails.billing.addressLine1" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.address_2') }}</div>
                      <InputText v-model="orderDetails.billing.addressLine2" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.postcode') }}</div>
                      <InputText v-model="orderDetails.billing.postcode" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.city') }}</div>
                      <InputText v-model="orderDetails.billing.city" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.phone') }}</div>
                      <InputText v-model="orderDetails.billing.phone" />
                    </div>
                    <div class="grid grid-cols-2 gap-2 content-center py-1" style="grid-template-columns: 1fr 2fr">
                      <div class="self-center">{{ t('change_profile.email_address') }}</div>
                      <InputText v-model="orderDetails.billing.email" />
                    </div>

                    <button
                      v-if="isAdmin"
                      class="bg-primary text-black w-44 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
                      @click="updateOrderBillingDetails(orderDetails)">
                      {{ t('change_profile.save_details') }}
                    </button>
                  </div>
                </OverlayPanel>
              </div>
              <div class="text-sm" v-if="isAdmin">
                {{ t('order_details.customer_number') }}: {{ orderDetails.customer?.customerId ? orderDetails.customer.customerId : t('order_details.unregistered') }}
              </div>
              <div class="text-sm" v-if="isAdmin">
                {{ t('order_details.email_address') }}: {{ orderDetails.userDetails?.email ? orderDetails.userDetails.email : t('order_details.unregistered') }}
              </div>
            </div>

            <div class="flex flex-col justify-center items-end">
              <div class="text-xs mb-1">
                {{ t('order_details.ordered_on') }}: {{ orderDetails.orderDate }}
              </div>
              <div class="text-sm mb-4">
                <span :class="`text-${orderDetails.status} mr-2`">&#9679;</span>
                {{ t(`order_status.${orderDetails.status}`) }}
              </div>
            </div>

          </div>

          <div class="flex flex-row w-full mb-6 mt-6">
            <div class="flex flex-col w-1/4">
              <div class="text-xs text-gray-400">
                {{ t('order_details.price') }}
              </div>
              <div class="text-lg">
                {{formatPrice(orderDetails.price)}}
              </div>
            </div>
            <div class="flex flex-col w-1/4" v-if="isAdmin">
              <div class="text-xs text-gray-400">
                {{ t('order_details.payment_method') }}
              </div>
              <div class="text-sm">
                {{orderDetails.paymentType?.length > 0 ? orderDetails.paymentType : 'undefined' }}
              </div>
            </div>
            <div class="flex flex-col w-1/4" v-if="isAdmin">
              <div class="text-xs text-gray-400">
                {{ t('order_details.order_items_jira_status') }}
              </div>
              <div class="text-sm">
                <template v-for="orderDetailsItem in orderDetails.orderItems">
                  <div class="text-sm" v-for="status in calculateOrderItemStatus(orderDetailsItem.jiraIssues)" :key="status.key">
                    {{ status.key }}: {{ status.name }}
                  </div>
                </template>
                <template v-if="!orderHasJiraTicketsCreated()">
                  {{ t('order_details.no_tickets_created') }}
                </template>
              </div>
            </div>
            <div class="flex flex-col w-1/4" v-if="isAdmin">
              <div class="text-xs text-gray-400">
                {{ t('order_details.wc_status') }}
              </div>
              <div v-if="!isAdmin" class="text-sm">
                {{ t(`payment_status.${orderDetails.paymentStatus}`) }}
              </div>
              <div v-if="isAdmin" class="flex flex-col">
                <Dropdown
                  v-on:change="e => orderWcPaimentChange(e, orderDetails)"
                  class="styled-small"
                  v-model="orderDetails.paymentStatus"
                  v-if="isAdmin"
                  :options="getWcStatusList()"
                  optionValue="value"
                  optionLabel="name">
                </Dropdown>
              </div>
            </div>
          </div>

          <div class="flex flex-row w-full justify-between">
          <div v-if="isAdmin" class="flex flex-col">
            <div class="text-xs text-gray-400">
              {{ t('order_details.order_source') }}
            </div>

            <Dropdown
              :disabled="orderHasJiraTicketsCreated()"
              v-on:change="e => orderSourceChange(e, orderDetails)"
              class="styled-small"
              v-model="orderDetails.orderSource"
              v-if="isAdmin"
              :options="getOrderSourceOptions()"
              optionValue="value"
              optionLabel="name">
            </Dropdown>
          </div>

          <button
            v-if="isAdmin && orderHasAllItemsAssignedToJiraTypes() && !orderHasJiraTicketsCreated()"
            class="bg-primary text-black w-44 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
            @click="createJiraTickets(orderDetails)">
            {{ t('order_details.create_jira_tickets') }}
          </button>
        </div>
        </div>

        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr class=" h-12 py-2  text-gray-600 text-xs rounded-lg">
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.service') }}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.single_price') }}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.quantity') }}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.discount') }}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.total') }}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.status') }}
            </th>
            <th v-if="isAdmin" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.type') }}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{ t('order_details.table.action') }}
            </th>
          </tr>
          </thead>
          <tbody>
            <template v-for="(orderDetailsItem, key) in orderDetails.orderItems">
              <tr class="h-16 py-2 text-sm hover:bg-gray-100">
                <td class="px-6 py-4 whitespace-wrap text-xs">
                  {{ orderDetailsItem.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  {{ formatPrice(orderDetailsItem.individualPrice) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  {{ orderDetailsItem.quantity }}
                </td>
                <td class="py-1 whitespace-nowrap relative text-xs">
                  {{ orderDetailsItem.discount }}%
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  {{ formatPrice(orderDetailsItem.subtotal) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  <div v-if="orderDetailsItem.jiraIssues.length <= 0">
                    {{ t('order_details.table.open') }}
                  </div>
                  <div
                    v-for="status in calculateOrderItemStatus(orderDetailsItem.jiraIssues)" :key="status.name">
                    {{ status.name }}
                  </div>
                </td>
                <td v-if="isAdmin">
                  <Dropdown
                    :disabled="orderHasJiraTicketsCreated()"
                    class="styled-small"
                    v-model="orderDetailsItem.jiraIssueType"
                    v-if="isAdmin"
                    v-on:change="updateOrderLineJiraIssueType(orderDetailsItem)"
                    :options="jiraIssueTypes"
                    optionValue="value"
                    optionLabel="name">
                  </Dropdown>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  <button
                    @click="forceBreakdown(orderDetailsItem.orderItemId)"
                    class="h-8 border border-primary rounded-3xl p-3 flex justify-center
                    items-center text-xs text-primary hover:bg-primary hover:text-white
                    mr-2 focus:outline-none">
                    {{ t('order_details.table.details') }}
                  </button>
                </td>
            </tr>
          </template>

          </tbody>
        </table>

      </div>

<!--
  BREAKDOWN
-->


      <div v-if="showBreakdown" >
        <div class="accordions mb-6 mx-2 w-1/5 relative p-5">
          <dl v-for="(row, id) in rows" :key="id">
            <dt v-on:click="row.open = !row.open">{{ row.term }}
              <span class="accordion-icon" v-bind:class="{ open: row.open }">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </dt>
            <dd v-if="row.open">
              <p>
                Wir bedanken uns bei Ihnen für Ihr Vertrauen. Sollten Sie Unterstützung benötigen, melden Sie sich bitte jederzeit unter folgenden Möglichkeiten:
              </p>
              <ul>
                <li>- Telefon: +49 (201) 4585 34 74</li>
                <li>- E-Mail: kontakt@deinguterruf.de</li>
              </ul>
              <p>
                Wir freuen uns auf Ihre Kontaktaufnahme!
              </p>
              <p>
                Ihr Dein Guter Ruf Team
              </p>
            </dd>
          </dl>
        </div>
        <div class="flex flex-row h-full overflow-hidden">
          <div class="mb-6 mx-2 w-1/5 relative p-5">
            <div class="mb-6">
              {{ t('order_details.table.your_booked_product') }}
            </div>
            <div class="orderItem" v-for="(orderItem, index) in orderDetails.orderItems">
              <div
                class="w-full mb-5 background-white text-darkgreen flex shadow-primary rounded-tl-lg
                rounded-bl-lg rounded-br-lg p-3 text-sm flex justify-center items-center select-none
                cursor-pointer font-bold"
                :class="isButtonActive(orderItem.orderItemId) ? 'bg-darkgreen text-white' : ''"
                @click="onButtonClick(orderItem.orderItemId)">
                <div class="mr-3">
                  {{ orderItem.customerOrders.length }}x
                </div>
                <div>
                  {{ orderItem.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="right-column w-4/5 h-full overflow-x-scroll no-scrollbar pb-8">
            <div class="orderItem" v-for="(orderItem, index) in orderDetails.orderItems" :key="index">
              <div
                v-if="isButtonActive(orderItem.orderItemId)"
                class="orderItemCustomerOrder"
                :class="isPending(index * 1000 + jndex)? 'opacity-20 pointer-events-none' : ''"
                v-for="(orderItemCustomerOrder, jndex) in orderItem.customerOrders"
                :key="jndex"
              >
                <div
                  class="flex my-6 mx-2 flex-col relative shadow-primary p-5 rounded-lg"
                  v-cloak
                  @drop.prevent="e => onFileDropped(e, orderItemCustomerOrder.orderItemBreakdownId, addFile)"
                  @dragover.prevent>

                  <div class="flex justify-between mb-4">
                    <div class="flex flex-col justify-center w-full">
                      <div class="text-xs mb-1 text-gray-400">
                        {{ t('order_details.breakdown.service_ordered') }}
                      </div>
                      <div class="text-base font-bold mb-4 flex place-content-between">
                        <div>
                        {{ orderItem.name }}
                        </div>
                        <div class="text-base flex place-content-between">
                          <div class="ml-3">
                            {{ orderItemCustomerOrder.jiraStatus ? orderItemCustomerOrder.jiraStatus : '' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="two-columns flex flex-row">

                    <div
                      v-if="['10032', '10006', '10034' ].includes(orderItem.jiraIssueType)"
                      class="flex flex-col w-2/4 mb-4 pr-5">
                      <div class="flex flex-col">
                        <div class="text-xs text-gray-400 mb-2">
                          {{ t('order_details.breakdown.urlUrl') }}
                        </div>
                        <div class="mt-2 text-xs">
                          <InputText
                            class="w-full"
                            v-model="orderItemCustomerOrder.urlUrl"
                            :disabled="orderHasJiraTicketsCreated()"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="['10030', '10009', '10033'].includes(orderItem.jiraIssueType)"
                      class="flex flex-col w-2/4 mb-4 pr-5">
                      <div class="flex flex-col w-full">
                        <div class="text-xs text-gray-400 mb-2">
                          {{ t('order_details.breakdown.url_address') }}
                        </div>
                        <div class="mt-2 text-xs">
                          <template v-if="!orderHasJiraTicketsCreated()">
                            <Dropdown
                              class="styled-small w-full"
                              :editable="true"
                              v-model="orderItemCustomerOrder.urlGmaps"
                              :options="getAvailableAddresses()">
                            </Dropdown>
                          </template>
                          <template v-else>
                            <Textarea
                              :disabled="true"
                              :autoResize="true"
                              class="mt-2 w-full text-xs"
                              rows="3"
                              v-model="orderItemCustomerOrder.urlGmaps" />
                          </template>
                        </div>
                      </div>


                      <div class="flex flex-col w-full mt-5">
                        <div class="text-xs text-gray-400 mb-2">
                          {{ t('order_details.breakdown.name_comment') }}
                        </div>
                        <div class="mt-2 text-xs">
                          <InputText
                            class="w-full"
                            v-model="orderItemCustomerOrder.urlName"
                            :disabled="orderHasJiraTicketsCreated()"
                          />
                        </div>
                      </div>



                    </div>

                    <div
                      class="flex flex-col w-2/4 mb-4 pl-5">
                      <!-- v-if="['10032', '10006' , '10034'].includes(orderItemCustomerOrder.jiraIssueType)"-->
                      <div
                        class="flex flex-col justify-center">
                        <div class="text-xs text-gray-400 mb-2">
                          {{ t('order_details.breakdown.explanation') }}
                        </div>
                        <div class="text-xs">
                          <Textarea
                            :disabled="orderHasJiraTicketsCreated()"
                            class="mt-2 w-full"
                            :autoResize="true"
                            rows="6"
                            v-model="orderItemCustomerOrder.urlDescription" />
                        </div>
                      </div>
                    </div>

                  </div>




                  <div class="two-columns flex flex-row mb-2 mt-5">
                    <div
                      class="image-placeholders grid pl-5 w-6/12"
                      style="grid-template-columns: 1fr 1fr 1fr 1fr">
                      <div
                        v-for="attachment in orderItemCustomerOrder.attachments"
                        class="image w-24 mr-5 mb-5">
                        <a :href="attachment.url" target="_blank">
                          <img :src="getThumbnail(attachment, 150)"/>
                          <div class="text-xs text-gray-400 text-center break-all">
                            {{ attachment.originalName }}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>


                  <!-- <button
                    v-if="!orderHasJiraTicketsCreated()"
                    class="bg-primary text-black w-44 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
                    @click="onUpdateCustomOrder(index * 1000 + jndex, index, jndex)"
                    :ref="setButtonRef">
                    {{ t('order_details.breakdown.submit') }}
                  </button> -->
                  <button @click="onUpdateCustomOrder(index * 1000 + jndex, index, jndex)" :ref="setButtonRef"></button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {ref, computed, onMounted , watch , onBeforeUpdate} from 'vue';
import { useStore } from 'vuex';
import {useI18n} from 'vue-i18n';
import httpClient from '@/services/httpClient';
import UStatusDropdown from './UStatusDropdown.vue';
import HelpModal from '@/components/HelpModal.vue';
import InformationModal from '@/components/InformationModal.vue';

export default ({
  name: 'OrderOverview',
  components: {
    UStatusDropdown,
    HelpModal,
    InformationModal
  },
  props: {
    getOrderDetails: Function,
    fetchOrderDetails: Function,
    class: String,
  },
  setup(props) {
    const fetchOrderDetails = props.fetchOrderDetails;
    const files = ref([]);
    const billingDetails = ref();
    const store = useStore();
    const {t} = useI18n();
    const fileRefs = ref([]);
    const showSummary = ref(false);
    const showBreakdown = ref(true);
    const orderDetails = props.getOrderDetails();
    const pending = ref();
    const jiraInProgress = ref();
    const buttonsActive = ref([]);
    const isAdmin = computed(() => store.getters['AuthModule/isAdmin']);
    const isCustomer = computed(() => store.getters['AuthModule/isCustomer']);
    const jiraIssueTypes = ref([]);
    const unsavedChanges = ref(false);
    const onUnsavedWatcherCount = ref(0)
    const saveAllClicked = ref(false)
    const showCreateOrder = ref(false);
    const showSaveModal = ref(false);
    const divs = ref([])
    const rows = ref([{
      term: 'Willkommen bei den Bestelldetails Ihrer Bestellung.',
      open: true
    }])

    let buttonsRefs = [];


    const setButtonRef = el => {
      if (el) {
        buttonsRefs.push(el)
      }
    }

    onBeforeUpdate(() => {
      buttonsRefs = []
    })

    watch(
      () => orderDetails,
      (state, prevState) => {
        if(onUnsavedWatcherCount.value > 0){
          unsavedChanges.value = true
        }
        else
          onUnsavedWatcherCount.value++
      },
      { deep: true }
    )

    const toggleBillingDetails = (event) => {
      billingDetails.value.toggle(event);
    };

    function setCurrentTab(current) {
      switch (current) {
        case 'summary':
          showSummary.value = true;
          showBreakdown.value = false;
          break;
        case 'breakdown':
          showSummary.value = false;
          showBreakdown.value = true;
          break;
      }
    }

    const getAvailableAddresses = () => {
      const ud = orderDetails.value.userDetails;

      const addressLine1 = ud.addressLine1.length? `${ud.addressLine1}, ` : ``;
      const addressLine2 = ud.addressLine2.length? `${ud.addressLine2}, ` : ``;
      const mainAddress = `${addressLine1} ${addressLine2} ${ud.postcode} ${ud.city}`
        .replace('  ', ' ')
        .trim();

      return [
        mainAddress,
        ...ud.additionalAddresses,
      ];
    }

    onMounted(async () => {
      jiraInProgress.value = true;
      const response = await httpClient.get(
        '/api/v1/dgr/jira_get_issue_types', {});

      jiraIssueTypes.value = response.data.map(f => { return { name: f.name, value: f.id } });

      jiraInProgress.value = false;
    });

    /**
     * Updating order breakdown item
     *
     * @param orderDetails
     * @returns {Promise<void>}
     */
    const onUpdateCustomOrder = async (i, orderItemIndex, customerOrderIndex) => {
      pending.value = i;
      const data = orderDetails.value.orderItems[orderItemIndex].customerOrders[customerOrderIndex];
      let formData = new FormData();

      if (files.value[data.orderItemBreakdownId]) {
        for (let i = 0; i < files.value[data.orderItemBreakdownId].length; i++) {
          let file = files.value[data.orderItemBreakdownId][i];
          formData.append('files[' + i + ']', file);
          formData.append('post[' + i + ']', data.orderItemBreakdownId);
        }

        const response = await httpClient.post(
          '/api/v1/dgr/media_upload',
          formData
        )

        orderDetails.value.orderItems[orderItemIndex].customerOrders[customerOrderIndex].attachments = response.data;
        files.value[data.orderItemBreakdownId] = [];
      }

      const response = await httpClient.post(
        '/api/v1/dgr/customer_order_update', {
          customerOrderId: data.orderItemBreakdownId,
          urlUrl: data.urlUrl,
          urlDescription: data.urlDescription,
          urlName: data.urlName,
          urlGmaps: data.urlGmaps,
        });
      pending.value = null;
    }


    /**
     * Updating order breakdown all items
     *
     * @param orderDetails
     * @returns {Promise<void>}
     */
    const onUpdateCustomOrderAllItems = async () => {
      buttonsRefs.forEach((name, i) => {
        setTimeout(() => {
          buttonsRefs[i].click()
        }, i * 1100);

        if (i === buttonsRefs.length - 1){
          console.log("All Saved");
          unsavedChanges.value = false
          setTimeout(() => {
            toggleSaveModal()
          }, 1000);
        }
      });
    }

    const onOpenHelpModal = async () => {
      console.log("TEST CLICKED")
    }

    /**
     * Update issue type for order item
     *
     * @param orderDetails
     * @returns {Promise<void>}
     */
    const updateOrderLineJiraIssueType = async (orderDetailsItem) => {
      jiraInProgress.value = true;

      const response = await httpClient.post(
        '/api/v1/dgr/order_item_update', {
          orderItemId: orderDetailsItem.orderItemId,
          jiraIssueType: orderDetailsItem.jiraIssueType,
        });
      jiraInProgress.value = false;
    }

    /**
     * Create Jira tickets
     *
     * @param orderDetails
     * @returns {Promise<void>}
     */
    const createJiraTickets = async (orderDetails) => {
      jiraInProgress.value = true;

      console.warn(orderDetails);
      const response = await httpClient.post(
        '/api/v1/dgr/jira_create_issues', {
          orderId: orderDetails.orderId,
        });
      console.warn('create response: ', response);

      await fetchOrderDetails();

      jiraInProgress.value = false;
    }

    const isPending = (i) => {
      return pending.value === i;
    }

    const orderHasAllItemsAssignedToJiraTypes = () => {
      const assigned = orderDetails.value.orderItems?.filter(f => f.jiraIssueType?.length > 1);
      return orderDetails.value.orderItems?.length === assigned?.length;
    }

    const orderHasJiraTicketsCreated = () => {
      const assigned = orderDetails.value.orderItems?.filter(
        f => Object.keys(f.jiraIssues).length
      );

      return orderDetails.value.orderItems?.length === assigned?.length;
    }

    const onButtonClick = (id) => {
      buttonsActive.value = [id];
      // buttonsActive.value.find(e => e === id)
      //   ? buttonsActive.value = buttonsActive.value.filter(e => e !== id)
      //   : buttonsActive.value.push(id)
    }

    const forceBreakdown = (section) => {
      buttonsActive.value = [section];
      showBreakdown.value = true;
      showSummary.value = false;
    }

    const isButtonActive = (id) => {
      if (buttonsActive.value.length === 0) {
        buttonsActive.value = [orderDetails.value?.orderItems[0]?.orderItemId];
        // orderDetails.value?.orderItems?.map(orderItem => buttonsActive.value.push(orderItem.orderItemId));
      }
      return buttonsActive.value.find(e => e === id);
    }

    const calculateOrderItemStatus = (jiraIssues) => {
      const statuses = Object.keys(jiraIssues)
        .map(
          (key, index) => {
            return { name: jiraIssues[key].statusName, key: jiraIssues[key].key }
          });
      return statuses;
    }

    const formatPrice = (price) => {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(price);
    };

    const updateOrderItemCustomerOrderStatus = (orderItemCustomerOrder, status) => {
      orderItemCustomerOrder.status = status;
      console.warn(orderItemCustomerOrder, status);
    }

    const addFile = (id, file) => {
      (files.value[id] || (files.value[id] = [])).push(file);
    }

    const onFileDropped = (e, id, addFile) => {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      ([...droppedFiles]).forEach((f) => {
        addFile(id, f);
      });
    }

    const removeFile = (id, file) => {
      files.value[id] = files.value[id].filter(f => {
        return f !== file;
      });
    }

    const onFileRemoved = (id, file, removeFile) => {
      removeFile(id, file);
    }

    const getThumbnail = (attachment, size) => {
      if (attachment.type.startsWith('image')) {
        return attachment.thumb.replace('{size}', `${size}x${size}`).replace('-scaled', '');
      } else {
        return 'https://via.placeholder.com/150';
      }
    }

    const uploadButtonChange = (e, id, addFile) => {
      for (let i in e.target.files) {
        if (e.target.files.hasOwnProperty(i)) {
          addFile(id, e.target.files[i]);
        }
      }
    }

    const getOrderSourceOptions = () => {
      return [
        { name: 'HomePage', value: '10055' },
        { name: 'Email', value: '10056' },
        { name: 'Telefon', value: '10057' },
        { name: 'DEVK', value: '10059' },
        { name: 'AXA-Assistance', value: '10060' },
        { name: 'Deutsche-Assistance', value: '10061' },
        { name: 'ARAG', value: '10062' },
        { name: 'Andere', value: '10058' },
      ]
    }

    const getWcStatusList = () => {
      return [
        // { name: 'ausstehend', value: 'wc-pending' },
        // { name: 'Verarbeitung', value: 'wc-processing' },
        // { name: 'ausstehende Bewilligung', value: 'wc-pending-approval' },
        // { name: 'in der Warteschleife', value: 'wc-on-hold' },
        // { name: 'Fehlgeschlagen', value: 'wc-failed' },
        // { name: 'Abgebrochen', value: 'wc-cancelled' },
        // { name: 'Abgeschlossen', value: 'wc-completed' },
        // { name: 'Rückerstattet', value: 'wc-refunded' },

        // { name: 'ausstehend', value: 'pending' },
        { name: 'Verarbeitung', value: 'processing' }, //
        // { name: 'ausstehende Bewilligung', value: 'pending-approval' },
        { name: 'in der Warteschleife', value: 'on-hold' }, //
        { name: 'Fehlgeschlagen', value: 'failed' }, //
        // { name: 'Abgebrochen', value: 'cancelled' }, //
        { name: 'Abgeschlossen', value: 'completed' },
        { name: 'Rückerstattet', value: 'refunded' },
      ]
    }

    const orderWcPaimentChange = async (e, orderDetails) => {
      console.log('orderWcPaimentChange', e, orderDetails);
      try {
        jiraInProgress.value = true;
        await httpClient.post(
        '/api/v1/dgr/order_update', {
          order_id: orderDetails.orderId,
          payment_status: e.value,
        });

        jiraInProgress.value = false;
      } catch (e) {
        console.log(e)
        jiraInProgress.value = false;
      }
    }

    const orderSourceChange = async (e, orderDetails) => {
      console.warn('order source', e, orderDetails);
      jiraInProgress.value = true;

      const response = await httpClient.post(
        '/api/v1/dgr/order_update', {
          order_id: orderDetails.orderId,
          orderSource: e.value,
        });
      jiraInProgress.value = false;
    }

    const setOrderItemAddress = (orderItem) => {
      console.warn(orderItem);
    }

    const updateOrderBillingDetails = async (orderDetails) => {
      console.warn('update order billing details', orderDetails);
      jiraInProgress.value = true;

      const response = await httpClient.post(
        '/api/v1/dgr/order_update', {
          order_id: orderDetails.orderId,
          orderBillingDetails: orderDetails.billing,
        });

      billingDetails.value.hide();
      jiraInProgress.value = false;
    }

    function getShowCreateOrder() {
      return showCreateOrder.value;
    }

    function getShowSaveModal() {
      return showSaveModal.value;
    }

    function toggleCreateOrder() {
      showCreateOrder.value = !showCreateOrder.value;
    }

    function toggleSaveModal() {
      showSaveModal.value = !showSaveModal.value;
    }

    return {
      t,
      setCurrentTab,
      showSummary,
      showBreakdown,
      isButtonActive,
      buttonsActive,
      orderDetails,
      onButtonClick,
      isPending,
      formatPrice,
      onUpdateCustomOrder,
      forceBreakdown,
      isAdmin,
      isCustomer,
      updateOrderItemCustomerOrderStatus,
      updateOrderLineJiraIssueType,
      files,
      addFile,
      onFileDropped,
      removeFile,
      onFileRemoved,
      getThumbnail,
      uploadButtonChange,
      fileRefs,
      jiraIssueTypes,
      jiraInProgress,
      createJiraTickets,
      orderHasAllItemsAssignedToJiraTypes,
      orderHasJiraTicketsCreated,
      calculateOrderItemStatus,
      getAvailableAddresses,
      setOrderItemAddress,
      getOrderSourceOptions,
      orderSourceChange,
      billingDetails,
      toggleBillingDetails,
      updateOrderBillingDetails,
      onUpdateCustomOrderAllItems,
      onOpenHelpModal,
      setButtonRef,
      unsavedChanges,
      divs,
      rows,
      getShowCreateOrder,
      toggleCreateOrder,
      getShowSaveModal,
      toggleSaveModal,
      getWcStatusList,
      orderWcPaimentChange
    };
  },
});

</script>

<style lang="scss">
  .accordions {
    width: 100%;
    padding-bottom: 0;
  }
  dl {
    margin: 0;

    dt {
      background-color: rgb(190 226 128 / 87%);
      padding: 10px 15px;
      font-weight: bold;
      position: relative;
    }

    .accordion-icon {
      position: absolute;
      top: 10px;
      right: 15px;
      &.open {
        transform: rotate(180deg);
      }
    }

    dd {
      padding: 0;
      margin: 0;
      border: 1px solid rgb(190 226 128 / 87%);
      border-top: 0;
      padding: 10px 15px;
      font-size: 15px;
      cursor: pointer;
    }
  }
</style>
<style lang="scss">
input[type='checkbox'] {
  filter: grayscale(1) !important;
}

.p-dropdown {
  min-width: 220px;
}
.p-dropdown-label.p-inputtext {
  font-size: 12px;
  padding: 6px;
}
.p-dropdown-item {
  font-size: 12px;
}


.tabs-container {
  position: relative;
}

.unsaved-changes {
    margin-left: 0;
    position: absolute;
    right: 400px;
    top: -3px;
    color: red;
}

.help-button {
  background-color: #BAB1D4;
  border-color: #BAB1D4;
  color: #fff;
  &:hover {
    background-color: #EE6A6A;
    border-color: #EE6A6A;
    color: #fff;
  }
}
</style>
