<template>
  <div class="flex overflow-hidden">
    <Sidebar/>
    <div class="flex flex-col p-3 w-4/5 h-screen overflow-y-auto overflow-x-hidden">
      <div class="text-4xl font-bold mt-6 ml-4">{{ t('order_details.order') }} #{{orderDetails.orderId}}</div>
      <router-link to="/orders" class="w-full flex justify-start items-center mb-4 underline m-4 text-sm">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5">
          <path fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"/>
        </svg>
        {{ t('order_details.back_to_all_orders') }}
      </router-link>
      <OrderOverview
        :class="loaded ? '' : 'opacity-40 pointer-events-none'"
        :fetchOrderDetails="fetchOrderDetails"
        :getOrderDetails="getOrderDetails"/>
    </div>
  </div>
</template>

<script>
import {ref, responsive, onMounted} from 'vue';
import {useRoute} from 'vue-router';
import Sidebar from '@/components/common/Sidebar.vue';
import OrderOverview from '@/components/OrderOverview.vue';
import httpClient from '@/services/httpClient';
import {useI18n} from 'vue-i18n';

export default {
  components: {
    Sidebar,
    OrderOverview
  },
  setup() {
    const {id} = useRoute().params;
    const orderDetails = ref([]);
    const loaded = ref();
    const {t} = useI18n();


    const fetchOrderDetails = async () => {
      loaded.value = false;
      const result = await httpClient.get('/api/v1/dgr/order', {id});
      const serviceTypes = await httpClient.get('/api/v1/dgr/services', {});

      result.data.orderItems.map(i => {
        if (i.jiraIssueType.length === 0) {
          i.jiraIssueType =
            serviceTypes.data.find(s => s.serviceId === i.productId)?.jiraTicketType;
        }
      });

      console.warn(serviceTypes);
      orderDetails.value = result.data;

      console.warn('response ' + id, orderDetails.value);
      loaded.value = true;
    }

    onMounted(fetchOrderDetails);

    function getOrderDetails() {
      return orderDetails;
    }

    const updateOrderDetails = (orderDetails) => {
      orderDetails.value = orderDetails;
    }

    return {
      orderDetails,
      getOrderDetails,
      fetchOrderDetails,
      loaded,
      t
    }
  }
}
</script>
